import logo from './logo.svg';
import './App.css';
import axios from 'axios'
import React from 'react'
import {useParams, BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner, faCircleNotch, faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

let BaseURL = process.env.REACT_APP_BASEURL


function App() {
  return (
      <Router>
        <Switch>
          <Route path={"/:orderId"} children={<Page/>}/>
            <Route exact path={"/"} children={"PAGE NOT FOUND"}/>
        </Switch>
      </Router>
  );
}

const imageSize = "originalLargeUrl"

const Page = () => {
  let { orderId } = useParams();
  const [images, setImages] = React.useState([]);
  const [order, setOrder] = React.useState(null);
  const [error,setError] = React.useState(null)
  React.useEffect(()=> {
    axios.get(`${BaseURL}/public/fotomerchant/download/order/${orderId}`)
        .then(res=>{
            console.log(res)
            setOrder(res.data.Order)
            setImages(res.data.Images)
        })
        .catch(err=> {
            setError(1)
        })
  }, [])

    const downloadFilesHandler = ()=> {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `${order.subject.password}-images.zip`;

        images.forEach(async function(url) {
            const filename = url["originalFilename"]
            try {
                const file = await JSZipUtils.getBinaryContent(url[imageSize])
                zip.file(filename, file, {
                    binary: true
                });
                count++;
                if (count === images.length) {
                    zip.generateAsync({
                        type: 'blob'
                    }).then(function(content) {
                        saveAs(content, zipFilename);
                    });
                }
            } catch (err) {
                console.log(err);
            }
        });
    }
    const downloadFileHandler = (url,fileName)=> {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'image/png',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                saveAs(blob, fileName);
            })
    }

  return <div className={"mainLayout"}>
      <div className={"backgroundImage"}/>
      <div className={"panel"}>
          <div className={"header"}>
              <div className="clientLogoImage">
                  <img
                  src="https://prod-us-west-2.imgix.net/assets/01FFP59S0X25YQPEMBFA6HY7CE/images/616e2a3429a90.png?w=200&amp;q=75&amp;fit=clip&amp;s=1d712fca23bbb27d72d6943931a93742"
                  className="img-centre"/>
              </div>
          </div>
          {images.length>0 &&
          <div className={"sub-header"}><div onClick={downloadFilesHandler} className={"download-button"}>
              <div style={{padding: "5px"}}><FontAwesomeIcon icon={faDownload} /></div><div style={{padding: "5px"}}>DOWNLOAD ALL IMAGES</div></div></div>
              }
          <div className={"galleryViewView"}>
              {images.length>0 ? <div style={{width: "fit-content", margin:"auto"}}>
                      {images.map((image,index)=>{
                  return <div key={index} className={"image-card"}>
                  <img
                  src={image.originalSmallUrl}
                  className="img-centre"/>
                  <div className={"image-subtitle"}>
                  <div className={"download-button"} onClick={()=>downloadFileHandler(image[imageSize], `${order.subject.password}-image-${index}`)}>
                  <div style={{padding: "5px"}}><FontAwesomeIcon icon={faDownload} /></div><div style={{padding: "5px"}}>DOWNLOAD IMAGE</div>
                  </div>
                  </div>



                  </div>
              })}
              </div>:
                  error? <div className={"error-message"}>
                          <div>
                              <FontAwesomeIcon icon={faExclamationCircle} className={"error-icon"}/>
                          </div>
                          <div style={{padding:"20px"}}>
                              <p>Images could not be found.</p>
                              <p>
                                Please call 02 9265 6800 for help.
                              </p>
                          </div>
              </div>:
                      <div className={"loading-spinner"}><FontAwesomeIcon icon={faCircleNotch} spin/></div>



              }
          </div>

      </div>
  </div>
}

export default App;
